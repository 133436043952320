import React, { FC } from 'react'
import styled from 'styled-components'
import { shimmerCss } from 'components/presentational/shimmer/shimmerCss'
import { sideGap } from 'components/layout/MambaLayout/Secondary'
import { useAuthorized } from 'hooks/useAuthorized'

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 15px;
  ${({ theme }) => theme.marginLeft}: ${sideGap}px;
`

const Item = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 10px;
  ${shimmerCss}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`

const Text = styled.div`
  width: 80%;
  height: 16px;
  ${shimmerCss}
`

const Favourite = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 20px;
  ${shimmerCss}
`

const Contact: FC = () => {
  return (
    <Item>
      <Avatar />
      <TextContainer>
        <Text />
        <Text style={{ width: '60%' }} />
      </TextContainer>
      <Favourite />
    </Item>
  )
}

export const ContactListShimmer: FC = () => {
  const authorized = useAuthorized()

  if (authorized) {
    return (
      <Container data-name="contacts-shimmer">
        <Contact />
        <Contact />
        <Contact />
      </Container>
    )
  }

  return null
}
