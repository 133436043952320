import styled, { css } from 'styled-components'
import { maxDesktopSize, media } from 'components/presentational'

export const desktopWidthPadding = 20
export const mobileWidthPadding = 10

export const InternalWidthLimit = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 ${desktopWidthPadding}px;
  max-width: ${maxDesktopSize}px;
  width: 100%;

  ${media.tablet`
    margin: 0;
    padding: 0 ${mobileWidthPadding}px;
  `};
`

export const ExpandWidthLimitCss = css`
  margin: 0 -${desktopWidthPadding}px;
  ${media.tablet`
    margin: 0 -${mobileWidthPadding}px;
  `};
`

export const WidthWithoutPaddingCss = css`
  max-width: calc(100vw - ${desktopWidthPadding * 2}px);
  ${media.tablet`
    max-width: calc(100vw - ${mobileWidthPadding * 2}px);
  `};
`

export const WidthLimit = styled(InternalWidthLimit)`
  flex-grow: 1;
`
