import React from 'react'
import loadable from '@loadable/component'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const PhotoLineLoadable = loadable(() => import('./PhotoLineIndex'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Photo line split error', error)
    }
    return <DefaultLoader />
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
