import { useLayoutContext } from 'common/hooks/useLayoutContext'
import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { isModalOpen } from 'components/layout/ModalLayout/modalOpen'
import React from 'react'

export const useModalOpened = (): boolean => {
  const { baseUrl } = useLayoutContext()
  const { pathname, statusCode } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
      systemReducer: { statusCode },
    }) => ({ pathname, statusCode })
  )

  return isModalOpen(baseUrl, pathname, statusCode)
}

/**
 * @deprecated
 */
export const withModalOpened = (WrappedComponent) => {
  return (props) => {
    const modalOpened = useModalOpened()
    return <WrappedComponent modalOpened={modalOpened} {...props} />
  }
}
