import loadable from '@loadable/component'
import React from 'react'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { ContactListShimmer } from 'components/presentational/shimmer/ContactListShimmer'

export const ContactSideBarLoadable = loadable(
  () => import('./ContactsIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('contacts sidebar split error', error)
      }
      return <ContactListShimmer />
    })(defaultLoadableProps),
  }
)
