import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { useMemo } from 'react'

export const useOperationSystem = () => {
  const {
    osName,
  } = useShallowEqualSelector(({ systemReducer: { osName } }) => ({ osName }))

  return useMemo(() => osName, [osName])
}
