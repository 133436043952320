import React from 'react'
import { AuthorizedRedirect } from 'components/system/AuthorizedRedirect'
import unauthorizedModalRoutes from './unauthorizedModalRoutes'

const filterUnauthorizedModalRoutes = unauthorizedModalRoutes.filter(
  ({ skip }) => !skip
)

/**
 * Авторизованный редирект с неавторизованных модалов.
 */
export const authorizedRedirectModalRoutes = [
  ...filterUnauthorizedModalRoutes.map(({ path }) => ({
    path,
    shouldCheck: true,
    component: () => <AuthorizedRedirect uid={`auth redirect "${path}"`} />,
  })),
]
