import { layoutSettingsPath } from 'components/layout/MambaLayout/paths'
import SettingsLoadable from 'components/page/Settings/SettingsLoadable'

import { GetLinkForDownloadAppLoadable } from 'components/page/GetLinkForDownLoadApp/GetLinkForDownloadAppLoadable'
import {
  cashOutPath,
  datingProfilePath,
  diamondsPath,
  downloadAppPath,
  logoutPath,
  featuredPhotosPath,
  appAgreementPath,
  appConfidentialityPath,
  guaranteedImpressionsResultPath,
  appAgreementIndexPath,
  appAgreementVipAppPath,
} from 'components/paths'
import { CashOutLoadable } from 'components/page/CashOut/CashOutLoadable'
import { DiamondsModalLoadable } from 'components/page/DiamondsInfo/DiamondsModalLoadable'
import { FeaturedPhotosStartLoadable } from 'components/storefront/StoreFrontFeaturedPhotos/FeaturedPhotosStartLoadable'
import commonModalRoutes from 'routes/commonModalRoutes'
import unauthorizedModalRoutes from 'routes/unauthorizedModalRoutes'
import StoreFrontLoadable from 'components/storefront/StoreFrontLoadable'
import DatingProfileLoadable from 'components/page/DatingProfile/DatingProfileLoadable'
import {
  StandaloneAgreementAndroidPage,
  StandaloneAgreementIOSPage,
  AgreementVipPage,
} from 'components/page/Legal'
import React from 'react'

import { AgreementLoadable } from 'components/page/Legal/Agreement/AgreementLoadable'
import { ConfidentialityLoadable } from 'components/page/Legal/Confidentiality/ConfidentialityLoadable'
import { LogoutModalLoadable } from 'components/layout/MambaLayout/LogoutModalLoadable'
import { searchSettingsPath } from 'components/page/Search/Search.paths'
import { MainSearchSettingsLoadable } from 'components/page/MainSearch/MainSearchSettingsLoadable'
import { landingFoundRoutes } from 'components/page/Landing/Landing.constants'
import { authorizedRedirectModalRoutes } from 'routes/authorizedRedirectModalRoutes'
import { GuaranteedImpressionsResultLoadable } from 'components/page/GuaranteedImpressionsResult/GuaranteedImpressionsResultLoadable'
import { storeFrontPath } from 'components/storefront/StoreFront.paths'
import { AppRoute } from 'components/components.types'
import { ModalRedirect } from 'components/system'
import {
  AlertPaymentLoadable,
  alertPaymentPagePath,
} from 'components/page/Boarding/AlertPayment/AlertPaymentLoadable'
import {
  agreementAndroidStandalonePath,
  agreementIOSStandalonePath,
} from 'components/page/Legal/Agreement/agreement.paths'
import { EditProfileInterestLoadable } from 'components/page/Interests/EditProfileInterestLoadable'
import { interestsPath } from 'components/page/Interests/Interests.paths'

const commonLayoutModalRoutes: AppRoute[] = [
  {
    path: agreementAndroidStandalonePath,
    component: StandaloneAgreementAndroidPage,
  },
  {
    path: agreementIOSStandalonePath,
    component: StandaloneAgreementIOSPage,
  },
  {
    path: appAgreementPath,
    // Иначе откроется на сером фоне
    component: () => (
      <ModalRedirect to={appAgreementIndexPath} uid="agreementPath" />
    ),
  },
  {
    path: appAgreementIndexPath,
    component: AgreementLoadable,
  },
  {
    path: alertPaymentPagePath,
    component: AlertPaymentLoadable,
  },
  {
    path: appAgreementVipAppPath,
    component: AgreementVipPage,
  },
  {
    path: appConfidentialityPath,
    component: ConfidentialityLoadable,
  },
  {
    path: diamondsPath,
    component: DiamondsModalLoadable,
    exact: false,
  },
  {
    path: searchSettingsPath,
    component: MainSearchSettingsLoadable,
    exact: false,
  },
]

const authorizedModalRoutes: AppRoute[] = [
  ...commonLayoutModalRoutes,
  ...commonModalRoutes,
  {
    path: interestsPath,
    component: EditProfileInterestLoadable,
    exact: false,
  },
  ...landingFoundRoutes,
  {
    path: storeFrontPath,
    component: StoreFrontLoadable,
    exact: false,
  },
  {
    path: cashOutPath,
    component: CashOutLoadable,
    exact: false,
  },
  {
    path: logoutPath,
    component: LogoutModalLoadable,
    exact: false,
    authorizedOnly: true,
  },
  {
    path: guaranteedImpressionsResultPath,
    component: GuaranteedImpressionsResultLoadable,
    exact: false,
  },
  {
    path: downloadAppPath,
    component: GetLinkForDownloadAppLoadable,
    exact: false,
  },
  {
    path: featuredPhotosPath,
    component: FeaturedPhotosStartLoadable,
    exact: false,
  },
  {
    path: datingProfilePath,
    component: DatingProfileLoadable,
    exact: false,
  },
  ...authorizedRedirectModalRoutes,
  // /activity/settingsss
  // ...landingFoundRoutes,
  {
    path: layoutSettingsPath,
    component: SettingsLoadable,
    exact: false,
    shouldCheck: true,
  },
  // indexRoute,
]

const unauthorizedMergeModalRoutes: AppRoute[] = [
  ...commonLayoutModalRoutes,
  ...commonModalRoutes,
  ...unauthorizedModalRoutes,
  ...landingFoundRoutes,
  // indexRoute,
]

export const createModalRoutes = (authorized: boolean) => {
  if (authorized) {
    return authorizedModalRoutes
  }
  return unauthorizedMergeModalRoutes
}
