import { bowserDesktopPlatformType } from 'common-constants/bowser'
import { feedPath } from 'components/paths'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { usePhotolineEnabled } from 'hooks/usePhotolineEnabled'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { createContext, useMemo } from 'react'

interface Value {
  photolineVisible: boolean
}

export const LayoutContext = createContext<Value>({
  photolineVisible: false,
})

export const useLayoutContextValue = (): Value => {
  const { platformType, baseUrl, web } = useShallowEqualSelector(
    ({ systemReducer: { platformType, baseUrl, web } }) => ({
      platformType,
      baseUrl,
      web,
    })
  )

  const hasUserRestrictions = useHasUserRestrictions()
  const isPhotolineEnabled = usePhotolineEnabled()

  const photolineVisible =
    !hasUserRestrictions &&
    platformType === bowserDesktopPlatformType &&
    web &&
    isPhotolineEnabled &&
    isPhotolineVisibleOnBaseUrl(baseUrl)

  return useMemo(() => {
    const value: Value = {
      photolineVisible: photolineVisible!,
    }

    return value
  }, [photolineVisible])
}

const isPhotolineVisibleOnBaseUrl = (baseUrl: string) => {
  if (baseUrl.includes(feedPath)) {
    return false
  }

  return true
}
