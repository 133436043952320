import React from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isLoveMailru } from 'common/constants'
import { NavigationLoveMailRuLoadable } from 'components/presentational/Navigation/NavigationLoveMailRuLoadable'
import { NavigationLoadable } from 'components/presentational/Navigation/NavigationLoadable'
import { contactRequestPath } from 'components/paths'

export const AppHeader = () => {
  const { partnerId, baseUrl, touch } = useShallowEqualSelector(
    ({ systemReducer: { partnerId, baseUrl, touch } }) => ({
      partnerId,
      baseUrl,
      touch,
    })
  )

  if (touch && baseUrl.startsWith(contactRequestPath)) {
    return null
  }

  if (partnerId && isLoveMailru(partnerId)) {
    return <NavigationLoveMailRuLoadable />
  }

  return <NavigationLoadable />
}
