import { useModalOpened } from 'hooks/useModalOpened'
import { useOperationSystem } from 'hooks/useOperationSystem'
import { modalOpenAttribute } from 'components/layout/ModalLayout/modalOpen'
import { WrapperElement } from 'components/layout/ModalLayout/WrapperElement'
import React, { FC } from 'react'
import { VoidHandler, WithChildren, WithDataName } from 'common/types'

export const ModalWrapper: FC<
  {
    isAuthPage?: boolean
    isMambaPartner?: boolean
    onClick?: VoidHandler
  } & WithDataName &
    WithChildren
> = (props) => {
  const modalOpened = useModalOpened()
  const operationSystem = useOperationSystem()

  const newProps = {
    ...props,
    [modalOpenAttribute]: modalOpened,
  }
  /* id необходимо для знакомства для фикса https://redmine.mamba.ru/issues/99606 */
  return (
    <WrapperElement
      id={'app-wrapper'}
      {...newProps}
      $operationSystem={operationSystem}
    />
  )
}
