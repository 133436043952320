import styled from 'styled-components'
import { media } from 'components/presentational'

export const secondaryMaxWidth = 340
export const sideGap = 10

export const Secondary = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: ${secondaryMaxWidth}px;
  max-width: ${secondaryMaxWidth}px;
  ${({ theme }) => theme.marginLeft}: -${sideGap}px;

  ${media.tabletLarge`
    display: none;
  `};
`
